import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { createUser, getUsers } from '../../app/reducers/Users/usersSlice';
import {
    getOrganization,
    fetchOrganizations,
} from '../../app/reducers/Organization/organizationSlice';
import { generateOptions } from '../../utils/Utils';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { USER_ROLES } from '../../utils/dropdownOptions';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
const AddUser = () => {
    const { loading } = useSelector(getUsers);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchOrganizations());
    }, []);
    const { organization } = useSelector(getOrganization);

    const organizationData = useMemo(
        () => (organization?.docs ? organization.docs : []),
        [organization]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            username: '91',
            password: '',
            email: '',
            role: '',
            address: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            username: Yup.string().required(),
            role: Yup.string().required(),
            password: Yup.string().required(),
            email: Yup.string().email(),
            address: Yup.string(),
        }),
        onSubmit: (values) => {
            dispatch(
                createUser({
                    ...values,
                })
            ).then((res) => {
                formik.resetForm();
                // console.log(res.payload.data.enrollmentUrl);
            });
        },
    });

    return (
        <PageWithCard heading="Add Users">
            <div className="text-slate-800 font-semibold mb-4">User Detail</div>
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikInputGroup
                        label="Name"
                        formik={formik}
                        name="name"
                        required
                    />
                    <FormikPhoneInputGroup
                        formik={formik}
                        label="Phone"
                        required
                        name="username"
                    />
                    <FormikInputGroup
                        label="Password"
                        formik={formik}
                        name="password"
                        type="password"
                    />
                    <FormikInputGroup
                        label="Email"
                        formik={formik}
                        name="email"
                    />

                    <FormikSelectGroup
                        label="Role"
                        formik={formik}
                        name="role"
                        required
                        options={generateOptions({
                            array: USER_ROLES,
                            labelField: 'label',
                            valueField: 'value',
                        })}
                    />
                    <FormikTextareaGroup
                        label="Address"
                        formik={formik}
                        name="address"
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default AddUser;
