import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import QueryString from 'qs';
const initialState = {
    items: {},
    loading: false,
};

export const fetchItems = createAsyncThunk(
    'items/fetchItems',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const itemsData = await authAxiosInstance.get(`items?${string}`);
            return itemsData.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch items');
        }
    }
);
export const updateItem = createAsyncThunk(
    'items/updateItem',
    async (data, { rejectWithValue }) => {
        try {
            const itemsResp = await authAxiosInstance.put(`items/update`, data);
            return itemsResp.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch items');
        }
    }
);
const itemSlice = createSlice({
    name: 'items',
    initialState: initialState,
    reducers: {
        reset: (state, action) => {
            state['items'] = [];
        },
    },
    extraReducers: {
        [fetchItems.pending]: (state) => {
            state.loading = true;
        },
        [fetchItems.fulfilled]: (state, action) => {
            state.loading = false;
            state.items = action.payload;
        },
        [fetchItems.rejected]: (state, action) => {
            state.loading = false;
        },
        [updateItem.pending]: (state) => {
            state.loading = true;
        },
        [updateItem.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [updateItem.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getItems = (state) => state.items;
export const { reset } = itemSlice.actions;
export default itemSlice.reducer;
