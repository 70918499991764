import { FieldArray, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
    editLocation,
    fetchLocation,
    fetchLocations,
    getLocation,
    setEditId,
} from '../../app/reducers/Location/locationSlice';
import EditModal from '../../components/infrastructure/Modals/EditModal';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { X } from 'react-feather';
import geo from '../../assets/countries+states+cities.json';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';

import {
    findCitiesInState,
    findStatesInCountry,
    generateOptions,
} from '../../utils/Utils';

const EditLocationModal = () => {
    const dispatch = useDispatch();

    const {
        elementEditId,
        editDataLoading: loading,
        elementEditData,
    } = useSelector(getLocation);

    //   useDispatch()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        onSubmit: (values) => {
            dispatch(editLocation(values));
            dispatch(fetchLocations());
        },
    });
    useEffect(() => {
        if (elementEditId) {
            dispatch(fetchLocation({ _id: elementEditId }));
        }
    }, [elementEditId]);
    return (
        <EditModal
            editProperty={elementEditId}
            setEditProperty={setEditId}
            getEditData={fetchLocation}
            title="Edit Location"
        >
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 p-4"
                >
                    <FormikInputGroup
                        name="_id"
                        required
                        label="System Id"
                        readOnly
                        formik={formik}
                    />
                    <FormikInputGroup
                        name="name"
                        required
                        label="Name"
                        formik={formik}
                    />
                    <div className="mt-4">
                        <PrimaryButton type="submit">Edit</PrimaryButton>
                    </div>
                </form>
            )}
        </EditModal>
    );
};

export default EditLocationModal;
