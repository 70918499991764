import { useDispatch, useSelector } from 'react-redux';
import EditModal from '../../components/infrastructure/Modals/EditModal';
import { ClipLoader } from 'react-spinners';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    editQuality,
    fetchQualities,
    fetchQuality,
    getQuality,
    resetEditData,
    setEditId,
} from '../../app/reducers/Quality/qualitySlice';
import * as Yup from 'yup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { QUALITY_TYPE } from '../../utils/dropdownOptions';

const EditQuality = () => {
    const { elementEditId, elementEditData, editDataLoading } =
        useSelector(getQuality);
    const dispatch = useDispatch();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: elementEditData,
        validationSchema: Yup.object({
            name: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            await dispatch(editQuality(values));
            resetForm();
            dispatch(setEditId(null));
            dispatch(fetchQualities({ sort: { priority: -1 }, limit: 300 }));
        },
    });

    return (
        <EditModal
            editProperty={elementEditId}
            setEditProperty={setEditId}
            resetEditData={resetEditData}
            getEditData={fetchQuality}
            title="Edit Quality"
        >
            {editDataLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-2 p-4"
                >
                    <FormikInputGroup
                        label="SystemId"
                        name="_id"
                        formik={formik}
                        required
                        readOnly
                    />
                    <FormikInputGroup
                        label="Name"
                        name="name"
                        formik={formik}
                        required
                    />
                    <FormikInputGroup
                        label="HSN Code"
                        name="hsnCode"
                        formik={formik}
                        required
                    />

                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </EditModal>
    );
};

export default EditQuality;
