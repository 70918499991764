import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import {
    fetchPurchaseOrder,
    getPurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { useDispatch, useSelector } from 'react-redux';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import {
    fetchQualities,
    fetchQuality,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import AddQuantityTaka from './AddQuantityTaka';
import { PoDetailRow } from './PoDetailRow';
import {
    fetchLocations,
    getLocation,
} from '../../app/reducers/Location/locationSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const TableHeader = ({ headers }) => {
    return (
        <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
            <tr>
                {headers.map((header, i) => (
                    <th
                        key={i}
                        className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap break-words"
                    >
                        <div
                            className="font-semibold text-left break-words"
                            style={header.style}
                        >
                            {header.name}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
};

const ReceiveGreyPurchaseOrder = () => {
    const { id: poId } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [qtyTakaModel, setTakaModel] = useState(false);
    const [editData, setEditData] = useState(null);
    const { singlePurchaseOrder } = useSelector(getPurchaseOrder);
    const { quality } = useSelector(getQuality);
    const { location } = useSelector(getLocation);
    const locationInfoId = localStorage.getItem('locationInfoId');

    useEffect(() => {
        dispatch(fetchQualities());
        dispatch(fetchLocations());
    }, []);

    useEffect(async () => {
        await dispatch(fetchPurchaseOrder({ id: poId }));
    }, [poId]);

    const qualityData = useMemo(
        () => (quality?.docs ? quality.docs : []),
        [quality]
    );
    const locationData = useMemo(
        () => (location?.docs ? location.docs : []),
        [location]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            location: locationInfoId ? locationInfoId : '',
            vendorName: '',
            vendor: '',
            quality: '',
            qualityName: '',
            billNo: '',
            poDetails: [],
            brokerName: singlePurchaseOrder?.custom_field_hash?.cf_broker,
            date: singlePurchaseOrder?.date,
        },
        validationSchema: Yup.object({
            location: Yup.string().required(),
            vendor: Yup.string().required(),
            vendorName: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            try {
                let poDetails = formik.values.poDetails;
                setLoading(true);
                if (poDetails?.length > 0) {
                    let payload = {
                        quality: values.quality.split('/')[0],
                        location: values.location,
                        qualityName: values.qualityName,
                        billNo: values.billNo,
                        poDetails: values.poDetails,
                        vendor: values.vendor,
                        vendorName: values.vendorName,
                        purchaseOrderId: poId,
                    };
                    const resp = await authAxiosInstance.post(
                        `/greyPurchaseOrder`,
                        payload
                    );
                    if (resp.data) {
                        setLoading(false);
                        formik.resetForm();
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error('Failed to update data');
            }
        },
    });

    return (
        <div className="p-4">
            <AddQuantityTaka
                isViewTaka={qtyTakaModel}
                setViewTaka={setTakaModel}
                formik={formik}
                data={singlePurchaseOrder}
                editData={editData}
                setEditData={setEditData}
            />
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 w-full"
            >
                <h1 className="text-lg font-bold">
                    Receive Grey Purchase Order
                </h1>
                <div className="grid md:grid-cols-3 gap-2">
                    <FormikAsyncSelect
                        name="vendor"
                        label="Vendor"
                        formik={formik}
                        getOptions={async (value) => {
                            const action = await dispatch(
                                fetchContacts({
                                    search: value,
                                    contact_type: 'vendor',
                                })
                            );
                            const serverResp = action.payload.contacts.map(
                                (ele) => ({
                                    label: `${ele.contact_name}`,
                                    value: `${ele.contact_id}/${ele.contact_name}`,
                                })
                            );
                            return serverResp;
                        }}
                        onChange={(option) => {
                            const id = option.value.split('/')[0];
                            const vendor_name = option.value.split('/')[1];
                            formik.setFieldValue('vendorName', vendor_name);
                            formik.setFieldValue('vendor', id);
                        }}
                    />
                    <FormikInputDateGroup
                        name="date"
                        formik={formik}
                        label="Date"
                    />
                    <FormikInputGroup
                        name="billNo"
                        formik={formik}
                        label="Bill No."
                    />
                </div>
                <div className="grid md:grid-cols-3 gap-2">
                    <FormikInputDateGroup
                        name="billDate"
                        formik={formik}
                        label="Bill Date"
                    />
                    <FormikInputGroup
                        name="brokerName"
                        label="Broker Name"
                        readOnly
                        formik={formik}
                    />
                    <FormikSelectGroup
                        formik={formik}
                        name={`location`}
                        label={'Select Location'}
                        options={locationData.map((ele) => ({
                            label: ele.name,
                            value: ele._id,
                        }))}
                    />
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        console.log('check');
                    }}
                >
                    <FormikSelectGroup
                        formik={formik}
                        name={`quality`}
                        label={'Select Quality'}
                        options={qualityData.map((ele) => ({
                            label: ele.name,
                            value: `${ele._id}/${ele?.hsnCode}`,
                        }))}
                        onChange={(selectedOption) => {
                            formik.setFieldValue(
                                'quality',
                                selectedOption.value
                            );
                            formik.setFieldValue(
                                'qualityName',
                                selectedOption.label
                            );
                            setTakaModel(true);
                        }}
                    />
                    <div className="mt-3">
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="poDetails"
                                render={(arrayHelpers) => {
                                    return (
                                        <>
                                            <table className="table-auto w-full">
                                                <TableHeader
                                                    headers={[
                                                        {
                                                            name: 'Sr No.',
                                                            style: {
                                                                minWidth:
                                                                    '20px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Item Name',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'HSNCode',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Taka',
                                                            style: {
                                                                minWidth:
                                                                    '20px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Meter',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Rate',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Action',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                    ]}
                                                />
                                                <tbody className="text-sm">
                                                    {formik.values.poDetails.map(
                                                        (ele, i) => (
                                                            <tr
                                                                key={`${ele.product}_${i}`}
                                                            >
                                                                <PoDetailRow
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    index={i}
                                                                    element={
                                                                        ele
                                                                    }
                                                                    arrayHelpers={
                                                                        arrayHelpers
                                                                    }
                                                                />
                                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                    <PrimaryButton
                                                                        type="button"
                                                                        onClick={() => {
                                                                            console.log(
                                                                                ele,
                                                                                'check now'
                                                                            );
                                                                            setEditData(
                                                                                {
                                                                                    ...ele,
                                                                                    curIndex:
                                                                                        i,
                                                                                }
                                                                            );
                                                                            setTakaModel(
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </PrimaryButton>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    );
                                }}
                            ></FieldArray>
                        </FormikProvider>
                    </div>
                    <div className="mt-3">
                        {loading ? (
                            <ClipLoader />
                        ) : (
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ReceiveGreyPurchaseOrder;
