import React from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import _ from 'lodash';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Clock, Edit2, PhoneCall } from 'react-feather';
import { useState } from 'react';
import {
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import EditPurchaseModal from './EditPurchaseModal';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import { useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import EditMenu from '../../components/DropdownEditMenu/DropdownEditMenu';
import axios from 'axios';
import { toast } from 'react-toastify';
import { customIDs } from '../../utils/customIds';
import AttachmentModal from './AttachmentModal';
import ContactModal from './ContactModal';
import EditDateModal from '../SplitQuarterData/EditDateModal';
import PrimaryBadge from '../../components/infrastructure/Badges/PrimaryBadge';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader, ClockLoader } from 'react-spinners';

const ViewPurchaseOrder = () => {
    const [page, setPage] = useState(1);
    const [viewPurchaseModal, setPurchaseModal] = useState(false);
    const [loadingReminder, setLoadingReminder] = useState(false);

    const [viewAttachmentModal, setAttachmentModal] = useState(false);
    const [attachmentData, setAttachmentData] = useState(false);
    const [editData, setEditData] = useState({});
    const [spitModal, setSplitModal] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [contactDetails, setContactDetails] = useState({});
    const [editDateModalOpen, setEditDateModalOpen] = useState(false);
    const [editPoId, setEditPoId] = useState(null);
    const navigate = useNavigate();

    const organization_id = 637857442;
    const dispatch = useDispatch();

    const { purchaseOrder, loading } = useSelector(getPurchaseOrder); // created on 16-11-22

    const purchaseOrdersData = useMemo(
        () =>
            purchaseOrder?.purchaseorders ? purchaseOrder.purchaseorders : [],
        [purchaseOrder]
    );

    const purchaseOrderPageContext = useMemo(
        () => (purchaseOrder?.page_context ? purchaseOrder.page_context : []),
        [purchaseOrder]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: 'vendor',
        },
    });

    // const convertBill = async (data) => {
    //     setButtonId(data.purchaseorder_id)
    //     dispatch(convertToBill({ id: data.purchaseorder_id}))
    //     dispatch(fetchPurchaseOrders({ page: page,status:'open' }))
    // }
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);

    useEffect(() => {
        dispatch(
            fetchPurchaseOrders({
                page: page,
                status: 'open',
                custom_field_597392000052372850: 'GREY PURCHASE',
            })
        );
    }, [page]);

    const cols = [
        {
            Header: 'PURCHASE ORDER#',
            Cell: ({ row, data }) => {
                return (
                    <div
                        className={
                            row?.original?.has_attachment
                                ? 'underline text-blue-500 cursor-pointer'
                                : 'cursor-pointer'
                        }
                        // onClick={(e) => {
                        //     e.stopPropagation();
                        //     setAttachmentData({
                        //         id: row.original.purchaseorder_id,
                        //         page: page,
                        //     });
                        //     setAttachmentModal(true);
                        // }}
                    >
                        {row.original.purchaseorder_number}
                    </div>
                );
            },
            style: {
                width: '12%',
            },
        },
        {
            Header: 'VENDOR NAME',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>{row?.original?.vendor_name}</span>
                    </div>
                );
            },
        },
        {
            Header: 'BROKER NAME',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex flex-col">
                        <div className="flex gap-4">
                            <span>{row?.original?.cf_broker}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: 'Master Grey Vendor',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex flex-col">
                        <div className="flex gap-4">
                            <span>{row?.original?.cf_master_grey_vender}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                return (
                    <div>{moment(row.original.date).format('DD/MM/YYYY')}</div>
                );
            },
            style: {
                width: '10%',
            },
        },
        {
            Header: 'Grey Quality',
            accessor: 'cf_grey_quality',
        },
        {
            Header: 'AMOUNT',
            accessor: 'total',
        },
        {
            Header: 'View',
            Cell: ({ row, data }) => {
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <PrimaryButton
                            color="blue"
                            className="cursor-pointer"
                            onClick={() => {
                                navigate(
                                    `/receivePO/${row.original.purchaseorder_id}`
                                );
                            }}
                        >
                            Receive
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];

    const handleNext = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            let data = {};
            if (formik.values.search == 'vendor') {
                data['vendor_name_contains'] = search;
            } else {
                data[
                    `custom_field_${customIDs.purchase_custom_buyerName}_contains`
                ] = search;
            }

            dispatch(
                fetchPurchaseOrders({
                    ...data,
                    page: 1,
                    status: 'open',
                })
            );
        }, 300),
        [JSON.stringify(formik.values)]
    );

    return (
        <div className="p-4">
            <EditPurchaseModal
                title={'Edit Purchase Order'}
                setPurchaseModal={setPurchaseModal}
                viewPurchaseModal={viewPurchaseModal}
                data={editData}
            />
            <AttachmentModal
                viewAttachmentModal={viewAttachmentModal}
                setAttachmentModal={setAttachmentModal}
                data={attachmentData}
            />
            <EditDateModal
                editDateModalOpen={editDateModalOpen}
                setEditDateModalOpen={setEditDateModalOpen}
                editPoId={editPoId}
                setEditPoId={setEditPoId}
            />
            <ContactModal
                setContactModalOpen={setContactModalOpen}
                contactModalOpen={contactModalOpen}
                contactDetails={contactDetails}
            />
            {/* <PrimaryButton
                className="mb-2"
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(fetchPurchaseOrders());
                }}
            >
                Fetch All
            </PrimaryButton> */}
            {/* <div className="mb-3">
                <FormikSelectGroup
                    formik={formik}
                    label="Search Type"
                    name={`search`}
                    onChange={(selectedOption) => {
                        dispatch(
                            fetchPurchaseOrders({
                                page: page,
                                status: 'open',
                            })
                        );
                        formik.setFieldValue('search', selectedOption.value);
                    }}
                    options={generateOptions({
                        array: [
                            { label: 'Vendor', value: 'vendor' },
                            { label: 'Customer', value: 'customer' },
                        ],
                        labelField: 'label',
                        valueField: 'value',
                    })}
                    required
                />
            </div> */}
            <TableWithHeadingAndGlobalSearch
                heading={'Purchase Orders'}
                placeholder={`Search for vendor`}
                data={purchaseOrdersData}
                loading={loading}
                columns={cols}
                searchFunction={(search) => {
                    debouncedSearch(search);
                }}
            />
            {!loading && (
                <div className="mt-4 flex gap-3 justify-end">
                    {page > 1 && (
                        <PrimaryButton onClick={(e) => setPage(page - 1)}>
                            Previous
                        </PrimaryButton>
                    )}
                    {purchaseOrderPageContext.has_more_page && (
                        <PrimaryButton onClick={(e) => handleNext(e)}>
                            Next
                        </PrimaryButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default ViewPurchaseOrder;
