import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { fetchItems, getItems } from '../../app/reducers/Items/items.slice';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { SUB_CATEGORY } from '../../utils/dropdownOptions';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader } from 'react-spinners';
import { customIDs } from '../../utils/customIds';
import FileUploadModal from './FileUploadModal';

const ViewItemsInventory = () => {
    const dispatch = useDispatch();
    const { items, loading: itemLoading } = useSelector(getItems);
    const [page, setPage] = useState(1);
    const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            subCategory: '',
        },
        onSubmit: async (values, { resetForm }) => {
            let data = {
                page: 1,
                per_page: 200,
                [`custom_field_${customIDs.item_master_category}`]:
                    'SALES ITEMS',
                [`custom_field_${customIDs.item_available_for_sale}`]: true,
            };
            if (values.subCategory) {
                data[`custom_field_${customIDs.item_sub_category}`] =
                    values.subCategory;
            }
            dispatch(fetchItems(data));
        },
    });

    useEffect(() => {
        let data = {
            page: page,
            per_page: 200,
            [`custom_field_${customIDs.item_master_category}`]: 'SALES ITEMS',
            [`custom_field_${customIDs.item_available_for_sale}`]: true,
        };
        if (formik.values.subCategory) {
            data[`custom_field_${customIDs.item_sub_category}`] =
                formik.values.subCategory;
        }
        dispatch(fetchItems(data));
    }, [page]);

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return (page - 1) * 200 + row?.index + 1;
            },
            disableSortBy: true,
        },
        {
            Header: 'Item Name',
            accessor: 'name',
            Cell: ({ row }) => {
                return row.original?.name;
            },
            style: {
                width: '170px',
            },
        },
        {
            Header: 'Sku',
            accessor: 'sku',
            Cell: ({ row }) => {
                return row.original?.sku;
            },
        },
        {
            Header: 'Description',
            Cell: ({ row }) => {
                return row.original?.description;
            },
            disableSortBy: true,
            style: {
                width: '150px',
            },
        },
        {
            Header: 'Purchase Description',
            Cell: ({ row }) => {
                return row.original?.purchase_description;
            },
            disableSortBy: true,
            style: {
                width: '150px',
            },
        },
        {
            Header: 'Purchase Rate',
            accessor: 'purchase_rate',

            Cell: ({ row }) => {
                return row.original?.purchase_rate;
            },
        },
        {
            Header: 'Rate',
            accessor: 'rate',
            Cell: ({ row }) => {
                return row.original?.rate;
            },
        },
        {
            Header: 'Stock On Hand',
            accessor: 'stock_on_hand',
            Cell: ({ row }) => {
                return row.original?.stock_on_hand || 0;
            },
        },
        {
            Header: 'Commited Stock',
            accessor: 'committed_stock',
            Cell: ({ row }) => {
                return parseInt(row.original?.committed_stock) || 0;
            },
        },
        {
            Header: 'Diff. Stock',
            accessor: (row) => {
                return (
                    (row?.original?.stock_on_hand || 0) -
                    (row?.original?.committed_stock || 0)
                );
            },
            sortType: (rowA, rowB) => {
                const diffA =
                    (rowA.original?.stock_on_hand || 0) -
                    (rowA.original?.committed_stock || 0);
                const diffB =
                    (rowB.original?.stock_on_hand || 0) -
                    (rowB.original?.committed_stock || 0);
                return diffA === diffB ? 0 : diffA > diffB ? 1 : -1;
            },
            Cell: ({ row }) => {
                return (
                    (row.original?.stock_on_hand || 0) -
                        row.original?.committed_stock || 0
                );
            },
        },
        {
            Header: 'Sale Status',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2">
                        <div>
                            <PrimaryButton
                                onClick={async () => {
                                    setLoading(row?.original?.item_id);
                                    let payloadData = {
                                        item_id: row?.original?.item_id,
                                        custom_fields: [
                                            {
                                                value: false,
                                                customfield_id:
                                                    customIDs.item_available_for_sale,
                                            },
                                        ],
                                    };
                                    const resp = await authAxiosInstance.put(
                                        'items/update',
                                        payloadData
                                    );
                                    if (resp.data) {
                                        let data = {
                                            page: page,
                                            per_page: 200,
                                            [`custom_field_${customIDs.item_master_category}`]:
                                                'SALES ITEMS',
                                            [`custom_field_${customIDs.item_available_for_sale}`]: true,
                                        };
                                        if (formik.values.subCategory) {
                                            data[
                                                `custom_field_${customIDs.item_sub_category}`
                                            ] = formik.values.subCategory;
                                        }
                                        dispatch(fetchItems(data));
                                        toast.success(
                                            'Item Updated Successfully'
                                        );
                                    }
                                    setLoading(null);
                                }}
                            >
                                {loading === row?.original?.item_id ? (
                                    <ClipLoader />
                                ) : (
                                    'Available for sale'
                                )}
                            </PrimaryButton>
                        </div>
                        <div>
                            <PrimaryButton
                                onClick={(e) => {
                                    console.log(row.original.item_id);
                                    e.stopPropagation();
                                    setIsFilesModalOpen(true);
                                    setSelectedFile(row.original.item_id);
                                }}
                            >
                                Add Files
                            </PrimaryButton>
                        </div>
                    </div>
                );
            },
        },
    ];

    const itemsData = useMemo(
        () => (items?.items ? items?.items : []),
        [items]
    );
    const itemsPageContext = useMemo(
        () => (items?.page_context ? items.page_context : []),
        [items]
    );

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            let data = {
                page: 1,
                per_page: 200,
                [`custom_field_${customIDs.item_master_category}`]:
                    'SALES ITEMS',
                [`custom_field_${customIDs.item_available_for_sale}`]: true,
            };
            if (formik.values.subCategory) {
                data[`custom_field_${customIDs.item_sub_category}`] =
                    formik.values.subCategory;
            }
            data['name_contains'] = search;
            dispatch(
                fetchItems({
                    ...data,
                    page: 1,
                    [`custom_field_${customIDs.item_master_category}`]:
                        'SALES ITEMS',
                    [`custom_field_${customIDs.item_available_for_sale}`]: true,
                })
            );
        }, 300),
        []
    );

    return (
        <>
            <FileUploadModal
                isFileUploadModalOpen={isFilesModalOpen}
                setIsFileUploadModalOpen={setIsFilesModalOpen}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
            />
            <div className="p-4">
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid grid-cols-2 gap-4 w-full"
                >
                    <h1 className="text-lg font-bold col-span-2">
                        Items Inventory
                    </h1>
                    <FormikSelectGroup
                        name="subCategory"
                        label="Sub Category"
                        formik={formik}
                        options={generateOptions({
                            array: SUB_CATEGORY,
                            labelField: 'label',
                            valueField: 'value',
                        })}
                    />
                    <div></div>
                    <div className="flex gap-3">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                        <PrimaryButton
                            type="button"
                            onClick={() => {
                                formik.resetForm();
                                let data = {
                                    page: 1,
                                    per_page: 200,
                                    [`custom_field_${customIDs.item_master_category}`]:
                                        'SALES ITEMS',
                                    [`custom_field_${customIDs.item_available_for_sale}`]: true,
                                };
                                dispatch(fetchItems(data));
                            }}
                        >
                            Clear
                        </PrimaryButton>
                    </div>
                </form>
                <div className="mt-2">
                    <TableWithHeadingAndGlobalSearch
                        placeholder={`Search for items`}
                        data={itemsData ? itemsData : []}
                        loading={itemLoading}
                        columns={cols}
                        searchFunction={(search) => {
                            debouncedSearch(search);
                        }}
                        heading={
                            <div className="flex gap-1">
                                <span className="text-xl">View Items</span>
                            </div>
                        }
                    />
                    {!itemLoading && (
                        <div className="mt-4 flex gap-3 justify-end">
                            {page > 1 && (
                                <PrimaryButton
                                    onClick={(e) => setPage(page - 1)}
                                >
                                    Previous
                                </PrimaryButton>
                            )}
                            {itemsPageContext.has_more_page && (
                                <PrimaryButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPage(page + 1);
                                    }}
                                >
                                    Next
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ViewItemsInventory;
