export const generateSetEditId = (element) => {
    return (state, action) => {
        state.elementEditId = action.payload;
    };
};
export const generateResetEditData = (initialValue) => {
    return (state, action) => {
        state.elementEditId = initialValue.elementEditId;
        state.elementEditData = {};
    };
};
