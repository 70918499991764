import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { useEffect } from 'react';
import { Trash2 } from 'react-feather';
import {
    createReminderSubject,
    deleteReminderSubject,
    fetchReminderSubjects,
    getReminderSubject,
} from '../../app/reducers/ReminderSubject/reminderSubjectSlice';
import * as Yup from 'yup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';

const ReminderSubject = () => {
    const { reminderSubject, loading } = useSelector(getReminderSubject);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchReminderSubjects());
    }, []);
    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            type: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            await dispatch(createReminderSubject(values));
            formik.resetForm();
            dispatch(fetchReminderSubjects());
        },
    });
    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row }) => row.index + 1,
        },
        {
            Header: 'name',
            accessor: 'name',
        },
        {
            Header: 'type',
            accessor: 'type',
        },
        {
            Header: 'Action',
            Cell: ({ row }) => (
                <>
                    <Trash2
                        onClick={async () => {
                            await dispatch(
                                deleteReminderSubject({ id: row.original._id })
                            );
                            dispatch(fetchReminderSubjects());
                        }}
                    />
                </>
            ),
        },
    ];
    return (
        <PageWithCard heading="Reminder Subject">
            <div className="flex flex-col gap-4">
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikInputGroup
                        formik={formik}
                        name={'name'}
                        label="Name"
                    />
                    <FormikSelectGroup
                        label="Type"
                        formik={formik}
                        name="type"
                        required
                        options={generateOptions({
                            array: [
                                { label: 'Customer', value: 'customer' },
                                { label: 'Vendor', value: 'vendor' },
                            ],
                            labelField: 'label',
                            valueField: 'value',
                        })}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit </PrimaryButton>
                    </div>
                </form>
                <TableWithHeadingAndSearch
                    columns={cols}
                    data={reminderSubject.docs ? reminderSubject.docs : []}
                    heading={'Reminder Subject'}
                    loading={loading}
                />
            </div>
        </PageWithCard>
    );
};

export default ReminderSubject;
