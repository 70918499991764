import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import QueryString from 'qs';
const initialState = {
    purchaseOrder: {},
    singlePurchaseOrder: {},
    bills: {},
    loading: false,
    purchaseOrderLoading: false,
};
export const fetchPurchaseOrders = createAsyncThunk(
    'purchaseOrders/fetchPurchaseOrders',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const purchaseOrder = await authAxiosInstance.get(
                `purchase-orders?${string}`
            );
            return purchaseOrder.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const fetchPurchaseOrder = createAsyncThunk(
    'purchaseOrders/fetchPurchaseOrder',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const purchaseOrder = await authAxiosInstance.get(
                `purchase-orders/${data.id}`
            );
            return purchaseOrder.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const createPurchaseOrder = createAsyncThunk(
    'purchaseOrders/createPurchaseOrder',
    async (data, { rejectWithValue }) => {
        try {
            const purchaseOrder = await authAxiosInstance.post(
                `purchase-orders`,
                data
            );
            console.log(purchaseOrder.data.data, 'check againf');
            toast.success('Purchase Order Created');
            return purchaseOrder.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const updatePurchaseOrder = createAsyncThunk(
    'purchaseOrders/updatePurchaseOrder',
    async (data, { rejectWithValue }) => {
        try {
            const purchaseOrder = await authAxiosInstance.put(
                `purchase-orders/open`,
                data
            );
            console.log(purchaseOrder.data.data);
            toast.success('Purchase Order Updated');
            return purchaseOrder.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const updatePurchaseOrderQuantity = createAsyncThunk(
    'purchaseOrders/updatePurchaseOrderQuantity',
    async (data, { rejectWithValue }) => {
        try {
            const purchaseOrder = await authAxiosInstance.put(
                `purchase-orders`,
                data
            );
            toast.success('Purchase Order Updated');
            return purchaseOrder.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch contacts');
        }
    }
);

export const convertToBill = createAsyncThunk(
    'purchaseOrders/convertToBill',
    async (data, { rejectWithValue }) => {
        try {
            const purchaseOrder = await authAxiosInstance.post(
                `purchase-orders/bill`,
                data
            );
            toast.success('Bill Created and Issued Successfully');
            return purchaseOrder.data.data;
        } catch (error) {
            console.log(error, 'New');
            if (error?.response?.data?.message)
                toast.error(error?.response?.data?.message);
            else toast.error('Something Went Wrong While Creating Bill');
        }
    }
);

export const getBills = createAsyncThunk(
    'purchaseOrders/getBills',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const bills = await authAxiosInstance.get(
                `purchase-orders/bill/detail?${string}`
            );
            return bills.data.data;
        } catch (error) {
            console.log(error, 'New');
            if (error?.response?.data?.message)
                toast.error(error?.response?.data?.message);
            else toast.error('Something Went Wrong While Creating Bill');
        }
    }
);

export const convertToBillWithDate = createAsyncThunk(
    'purchaseOrders/convertToBillWithDate',
    async (data, { rejectWithValue }) => {
        try {
            const purchaseOrder = await authAxiosInstance.post(
                `purchase-orders/bill/date`,
                data
            );
            toast.success('Bill Created and Issued Successfully');
            return purchaseOrder.data.data;
        } catch (error) {
            console.log(error, 'New');
            if (error?.response?.data?.message)
                toast.error(error?.response?.data?.message);
            else toast.error('Something Went Wrong While Creating Bill');
        }
    }
);

const purchaseOrderSlice = createSlice({
    name: 'purchaseOrder',
    initialState: initialState,
    reducers: {
        reset: (state, action) => {
            state['purchaseOrder'] = [];
        },
    },
    extraReducers: {
        [fetchPurchaseOrders.pending]: (state) => {
            state.loading = true;
        },
        [fetchPurchaseOrders.fulfilled]: (state, action) => {
            state.loading = false;
            state.purchaseOrder = action.payload;
        },
        [fetchPurchaseOrders.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchPurchaseOrder.pending]: (state) => {
            state.purchaseOrderLoading = true;
        },
        [fetchPurchaseOrder.fulfilled]: (state, action) => {
            state.purchaseOrderLoading = false;
            state.singlePurchaseOrder = action.payload?.purchaseorder;
        },
        [fetchPurchaseOrder.rejected]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
        [createPurchaseOrder.pending]: (state) => {
            state.loading = true;
        },
        [createPurchaseOrder.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [createPurchaseOrder.rejected]: (state, action) => {
            state.loading = false;
        },
        [updatePurchaseOrder.pending]: (state) => {
            state.loading = true;
        },
        [updatePurchaseOrder.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [updatePurchaseOrder.rejected]: (state, action) => {
            state.loading = false;
        },
        [updatePurchaseOrderQuantity.pending]: (state) => {
            state.purchaseOrderLoading = true;
        },
        [updatePurchaseOrderQuantity.fulfilled]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
        [updatePurchaseOrderQuantity.rejected]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
        [convertToBill.pending]: (state) => {
            state.purchaseOrderLoading = true;
        },
        [convertToBill.fulfilled]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
        [convertToBill.rejected]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
        [convertToBillWithDate.pending]: (state) => {
            state.purchaseOrderLoading = true;
        },
        [convertToBillWithDate.fulfilled]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
        [convertToBillWithDate.rejected]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
        [getBills.pending]: (state) => {
            state.purchaseOrderLoading = true;
        },
        [getBills.fulfilled]: (state, action) => {
            state.purchaseOrderLoading = false;
            state.bills = action.payload;
        },
        [getBills.rejected]: (state, action) => {
            state.purchaseOrderLoading = false;
        },
    },
});

export const getPurchaseOrder = (state) => state.purchaseOrder;
export const { reset } = purchaseOrderSlice.actions;
export default purchaseOrderSlice.reducer;
