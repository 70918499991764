import React from 'react';
import { X } from 'react-feather';
import _ from 'lodash';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';

const Row = function Row({ formik, index, element, arrayHelpers }) {
    return (
        <>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="flex gap-4">
                    <X
                        onClick={(e) => {
                            formik.setFieldValue('flatDiscount', 0);
                            arrayHelpers.remove(index);
                        }}
                    />
                    {index + 1}.
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{element?.qualityName}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{element?.hsnCode}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{element?.taka}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{element?.meter}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{element?.rate}</div>
            </td>
        </>
    );
};

export function PoDetailRow({ formik, index, element, arrayHelpers }) {
    return (
        <>
            <Row
                formik={formik}
                index={index}
                element={element}
                arrayHelpers={arrayHelpers}
            />
        </>
    );
}
