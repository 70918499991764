import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
    fetchLocations,
    getLocation,
} from '../../app/reducers/Location/locationSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { generateOptions, generateValueFromStores } from '../../utils/Utils';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useMemo } from 'react';

const AssignLocation = () => {
    const dispatch = useDispatch();
    const locationInfoId = localStorage.getItem('locationInfoId');
    useEffect(() => {
        dispatch(fetchLocations());
    }, []);

    useEffect(() => {
        formik.setFieldValue('store', locationInfoId);
    }, [locationInfoId]);

    const { location, loading } = useSelector(getLocation);

    const locationData = useMemo(
        () => (location?.docs ? location.docs : []),
        [location]
    );

    const formik = useFormik({
        initialValues: {
            store: '',
        },
        validationSchema: Yup.object().shape({
            store: Yup.string().required(),
        }),
        onSubmit: (values, { resetForm }) => {
            localStorage.setItem('locationInfoId', values.store);
            toast.success('Location assigned..');
        },
    });

    return (
        <PageWithCard heading="Assign Location">
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikSelectGroup
                        name="store"
                        formik={formik}
                        label="Store"
                        options={generateOptions({
                            array: locationData,
                            valueField: '_id',
                            labelField: 'name',
                        })}
                        required
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default AssignLocation;
