import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
    createInvoice,
    getInvoice,
} from '../../app/reducers/Invoice/invoice.slice';
import {
    editNotification,
    fetchNotifications,
} from '../../app/reducers/Notification/notificationSlice';
import moment from 'moment';
import { fetchContact } from '../../app/reducers/Customer/customerSlice';
import { customIDs } from '../../utils/customIds';
import QueryString from 'qs';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import * as Yup from 'yup';

const SplitPopUp = ({
    viewSplitModal,
    setSplitModal,
    title,
    data,
    updateNotification = true,
}) => {
    const dispatch = useDispatch();
    const { loading } = useSelector(getInvoice);
    const [customerDetails, setCustomerDetails] = useState({});

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            amount: '',
            date: new Date(),
        },
        validationSchema: Yup.object({
            date: Yup.mixed().required(),
            amount: Yup.number().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            let poUrlData = {
                customer: data.customerId,
                from: moment(data.from).format('YYYY-MM-DD'),
                to: moment(data.to).format('YYYY-MM-DD'),
            };
            let poString = QueryString.stringify(poUrlData);
            let invoiceData = {
                customer_id: data.customerId,
                payment_terms: 7,
                payment_terms_label: 'Net 7(-0%)',
                date: moment(values.date).format('YYYY-MM-DD'),
                due_date: moment(values.date)
                    .add(7, 'days')
                    .format('YYYY-MM-DD'),
                line_items: [
                    {
                        item_order: 1,
                        item_id: customIDs.invoice_item_id,
                        rate: values.amount,
                        name: 'Commission on grey nylon fabrics',
                        description: `From ${moment(data.from).format(
                            'DD MMM YYYY'
                        )} to ${moment(data.to).format('DD MMM YYYY')}`,
                        quantity: '1.00',
                        discount: '0%',
                        account_id: customIDs.invoice_item_account_id,
                        item_custom_fields: [
                            {
                                label: 'Qty Taka',
                                value: '1',
                            },
                            {
                                label: 'Unit Rate',
                                value: 1,
                            },
                            {
                                label: 'Vendor Name',
                                value: 'No',
                            },
                        ],
                        hsn_or_sac: '996111',
                    },
                ],
                gst_no: data.gst_no,
                tds_tax_id: customIDs.invoice_tds_tax_id,
                custom_fields: [
                    {
                        value: '0',
                        customfield_id: customIDs.invoice_rate_calculation,
                    },
                    {
                        value: `From ${moment(data.from).format('DD MMM YYYY')} to ${moment(
                            data.to
                        ).format('DD MMM YYYY')}`,
                        customfield_id: customIDs.invoice_statment_period,
                    },
                    {
                        value: '0',
                        customfield_id: customIDs.invoice_chalan_number,
                    },
                    {
                        value: data.customerPhone,
                        customfield_id: customIDs.invoice_mobile_number,
                    },
                    {
                        value: `https://admin.kukrejabullsagency.in/poReport?${poString}`,
                        customfield_id: customIDs.invoice_po_report_url,
                    },
                    {
                        value: `https://admin.kukrejabullsagency.in/billReport?${poString}`,
                        customfield_id: customIDs.invoice_bill_report_url,
                    },
                ],
            };
            let invoice = await dispatch(createInvoice(invoiceData));
            if (invoice && updateNotification) {
                dispatch(editNotification({ _id: data.id, isSplit: 'true' }));
                dispatch(fetchNotifications());
            }
            setSplitModal(false);
        },
    });

    return (
        <ModalBasic
            title={title}
            modalOpen={viewSplitModal}
            setModalOpen={setSplitModal}
        >
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        label="Amount"
                        formik={formik}
                        type="number"
                        name="amount"
                    />
                    <FormikInputDateGroup
                        name="date"
                        label="Date"
                        formik={formik}
                        required
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default SplitPopUp;
